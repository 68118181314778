import { Component, OnInit } from '@angular/core';
import {
  ActionSheetController,
  AlertController,
  ModalController,
  Platform,
} from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
import { ContactFormPage } from '../contact-form/contact-form.page';
import { GovukpayPage } from '../govukpay/govukpay.page';
import { MobipaidPage } from '../mobipaid/mobipaid.page';
import { PaymentPlanPage } from '../payment-plan/payment-plan.page';
import { USAepayPage } from '../usaepay/usaepay.page';
import { ArrangementPage } from '../arrangement/arrangement.page';
import { RecurringPaymentPlanPage } from '../recurring-payment-plan/recurring-payment-plan.page';
import { ReinitService } from 'src/app/services/reinit.service';
import { PayRocPage } from '../payroc/payroc.page';
import { GoCardlessPage } from '../gocardless/gocardless.page';
import { AcquiredPage } from '../acquired/acquired.page';
import * as moment from 'moment';

@Component({
  selector: 'app-details',
  templateUrl: './details.page.html',
  styleUrls: ['./details.page.scss'],
})
export class DetailsPage implements OnInit {
  caseList = [];
  debtorDetails;
  totalOutstanding;
  isMobile = false;

  paymentList: any = {
    mobipaid: {
      text: 'Mobipaid',
      handler: () => {
        this.goToMobipaid();
      },
    },
    usaepay: {
      text: 'USA ePay',
      handler: () => {
        this.goToPaymentAmountPopup();
      },
    },
    govukpay: {
      text: 'GOV.UK Pay',
      handler: () => {
        this.goToGovukpay();
      },
    },
    sagepay: {
      text: 'Sage Pay',
      handler: () => {},
    },
    payroc: {
      text: 'Pay Roc',
      handler: () => {
        this.goToPaymentAmountPopup();
      },
    },
    gocardless: {
      text: 'GoCardless',
      handler: () => {
        this.goToPaymentAmountPopup();
      },
    },
    acquired: {
      text: 'Acquired',
      handler: () => {
        this.goToPaymentAmountPopup();
      },
    },
  };
  availablePaymentListArray = [];
  paymentBtnDisabled = false;
  firstCaseId;
  firstCaseDetails;
  paymentGatewayList = [];
  arr_option = {};
  casePaymentLinks = [];
  showPersonalDetails = true;
  lateralClients = {
    lackfords: 'Lackfords',
  };
  currentClientName;
  totalOutstandingWithoutSymbol;
  defaultPaymentPortal = 'mobipaid';
  govuktitle = 'Lateral Payment Portal';
  field_list = [];
  fieldLabels = {};
  arrangement_options = [];
  availablePaymentPlanOptions = [];
  currency_symbol;
  use_new_recurring_payment = false;
  isActiveArrangementPresent = false;
  datepicker_date_format = 'DD-MM-YYYY';
  constructor(
    private caseService: CaseService,
    private platform: Platform,
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private commonService: CommonService,
    private alertController: AlertController,
    private reinitService: ReinitService
  ) {
    this.reinitService.reinit$.subscribe((shouldReinit) => {
      if (shouldReinit) {
        this.ionViewWillEnter(); // Manually trigger component reinitialization
      }
    });
  }

  ngOnInit() {
    this.isMobile = this.platform.is('mobile');
    var hostname = window.location.hostname;
    var url_split = hostname.split('.');
    this.currentClientName = this.lateralClients[url_split[0]]
      ? this.lateralClients[url_split[0]]
      : 'Us';
  }

  ionViewWillEnter() {
    this.getDebtorDetails();
    this.checkAvailablePaymentList();
  }

  ionViewDidEnter(){
  }

  getCaseDetails() {
    this.commonService.showLoader('Loading ...', 2000);
    this.caseService
      .getCaseDetails(localStorage.getItem('debtor_id'))
      .subscribe((res: any) => {
        if (res.result) {
          this.caseList = Object.values(res.data.caseList);
          this.firstCaseId = this.caseList[0]?.id;
          this.firstCaseDetails = this.caseList[0];
          this.firstCaseDetails.linked_cases = this.caseList.slice(1); // To handle group arrangement.
          if(this.firstCaseDetails?.arranagement 
            && this.firstCaseDetails.arranagement?.current_arrangement 
            && this.firstCaseDetails.arranagement.current_arrangement.length) {
            this.isActiveArrangementPresent = this.firstCaseDetails.arranagement.current_arrangement.length > 0 ? true : false;
          }
          // this.firstCaseDetails.linked_cases = [];
          this.paymentGatewayList = res.data.paymentGatewayList;
          this.totalOutstanding = res.data.totalOutstanding;
          this.totalOutstandingWithoutSymbol =
            res.data.totalOutstandingWithoutSymbol;
          localStorage.setItem('dateFormat',res.data.dateFormat);
          this.currency_symbol = this.caseList[0].currency_symbol;
          this.getPaymentPlanOptions();
        } else {
          this.commonService.showToast(res.message, 'danger');
        }
        this.commonService.dismissLoader();
      });
  }

  getDebtorDetails() {
    this.commonService.showLoader('Loading ..', 2000);
    this.caseService
      .getDebtorDetails(localStorage.getItem('debtor_id'))
      .subscribe((res: any) => {
        if (res.result) {
          this.debtorDetails = res.data;
        } else {
          this.commonService.showToast(res.message, 'danger');
        }
        this.commonService.dismissLoader();
        this.getCaseDetails();
      });
  }

  async presentActionSheet() {
    if (this.availablePaymentListArray.length >= 2) {
      let buttons = [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
      ];
      buttons = buttons.concat(this.availablePaymentListArray);
      const actionSheet = await this.actionSheetController.create({
        header: 'Available Payment Option',
        cssClass: 'my-custom-class',
        buttons: buttons,
      });
      await actionSheet.present();
    } else {
      switch (this.defaultPaymentPortal) {
        case 'mobipaid':
          this.goToMobipaid();
          break;
        case 'acquired':
        case 'gocardless':
        case 'usaepay':
        case 'payroc':
          this.goToPaymentAmountPopup(this.defaultPaymentPortal);
          break;
        case 'govukpay':
          this.goToGovukpay();
          break;
        case 'sagepay':
          break;
        default:
          break;
      }
    }
  }

  checkAvailablePaymentList() {
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.payment_type) {
      let payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
      this.availablePaymentListArray = [];
      Object.values(payment_type).forEach((value: any, index) => {
        if (index == 0) {
          this.defaultPaymentPortal = value.key;
        }
        if (this.paymentList[value.key]) {
          this.paymentList[value.key].text = value.label;
          this.availablePaymentListArray.push(this.paymentList[value.key]);
        }
      });
    }
    if (this.availablePaymentListArray.length == 0) {
      this.paymentBtnDisabled = true;
    }
    if (portalSettings?.arr_option) {
      this.arr_option = portalSettings?.arr_option
        ? portalSettings?.arr_option
        : {};
    }
    if (!Object.keys(this.arr_option).length) {
      this.arr_option = { request: true };
    }
    if (portalSettings?.fieldLabels) {
      this.fieldLabels = portalSettings.fieldLabels;
    }
    if (portalSettings?.field_list) {
      this.field_list = portalSettings?.field_list
        ? portalSettings?.field_list
        : [];
    }
  }

  getCaseFieldValue(value, caseField) {
    const currentCase = value;
    const res = caseField.split('.');
    res.forEach((r) => {
      let r1 = r.split('[');
      if (r1.length > 1) {
        value = value[r1[0]][0];
      } else {
        value = value[r];
      }
      if (Array.isArray(value)) {
        if (value[0]) {
          value = value[0];
        } else {
          value = [];
        }
      }
      if (r === 'address_postcode') {
        if (!value && currentCase.debtor.addresses[0].address_postcode) {
          value = currentCase.debtor.addresses[0].address_postcode;
        }
      }
      if (r === 'hold_until') {
        if (value != null && value > new Date()) {
          value = 'Yes';
        } else {
          value = 'No';
        }
      }
    });
    return value ? value : '-';
  }

  async goToMobipaid() {
    const modal = await this.modalController.create({
      component: MobipaidPage,
      componentProps: {
        caseId: this.firstCaseId,
        debtorDetails: this.debtorDetails,
      },
    });
    return await modal.present();
  }

  async goToUSAepay(amount) {
    this.caseService
      .makeUSAePaymentRequest(this.firstCaseId, { amount })
      .subscribe(async (res: any) => {
        if (res.result) {
          const modal = await this.modalController.create({
            component: USAepayPage,
            componentProps: {
              caseId: this.firstCaseId,
              totalOutstandingWithSymbol: this.totalOutstanding,
              debtorDetails: this.debtorDetails,
              responseData: res.data,
              caseDetails: this.firstCaseDetails,
            },
          });
          return await modal.present();
        } else {
          this.commonService.showToast(res['message'], 'danger');
          return false;
        }
      });
  }

  async goToPayRoc(amount) {
    this.caseService
    .makePayrocPaymentRequest(this.firstCaseId, { amount })
    .subscribe(async (res: any) => {
      if (res.result) {
        const modal = await this.modalController.create({
          component: PayRocPage,
          componentProps: {
            caseId: this.firstCaseId,
            totalOutstandingWithSymbol: this.totalOutstanding,
            responseData: res.data,
            caseDetails: this.firstCaseDetails,
          },
        });
        return await modal.present();
      } else {
        this.commonService.showToast(res['message'], 'danger');
        return false;
      }
    });
  }

  async goToGoCardless(amount) {
    this.caseService
      .makeGocardlessPaymentRequest(this.firstCaseId, { amount })
      .subscribe(async (res: any) => {
        if (res.result) {
          const modal = await this.modalController.create({
            component: GoCardlessPage,
            componentProps: {
              caseId: this.firstCaseId,
              totalOutstandingWithSymbol: this.totalOutstanding,
              responseData: res.data,
              caseDetails: this.firstCaseDetails,
            },
          });
          return await modal.present();
        } else {
          this.commonService.showToast(res['message'], 'danger');
          return false;
        }
      });
  }
  
  async goToAcquired(amount) {
    this.caseService
      .makeAcquiredPaymentRequest(this.firstCaseId, { amount })
      .subscribe(async (res: any) => {
        if (res.result) {
          const modal = await this.modalController.create({
            component: AcquiredPage,
            componentProps: {
              caseId: this.firstCaseId,
              totalOutstandingWithSymbol: this.totalOutstanding,
              responseData: res.data,
              caseDetails: this.firstCaseDetails,
            },
          });
          return await modal.present();
        } else {
          this.commonService.showToast(res['message'], 'danger');
          return false;
        }
      });
  }

  async goToPaymentPlan() {
    console.log('jhsdfsdf');
    const modal = await this.modalController.create({
      component: PaymentPlanPage,
      componentProps: {
        caseId: this.firstCaseId,
        totalOutstanding: this.totalOutstanding,
        isGroupArrangement: this.caseList.length > 1 ? true : false,
        debtorDetails: this.debtorDetails,
      },
    });
    return await modal.present();
  }

  async goToContactForm() {
    const modal = await this.modalController.create({
      component: ContactFormPage,
      componentProps: {
        caseId: this.firstCaseId,
      },
    });
    return await modal.present();
  }

  async goToGovukpay() {
    // const modal = await this.modalController.create({
    //   component: GovukpayPage,
    //   componentProps: {
    //     caseId: this.firstCaseId,
    //     totalOutstanding: this.totalOutstandingWithoutSymbol,
    //     firstCaseDetails: this.caseList[0],
    //     debtorDetails: this.debtorDetails
    //   }
    // });
    // return await modal.present();

    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.payment_type) {
      let payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
      if (payment_type?.govukpay && payment_type?.govukpay?.label) {
        this.govuktitle = payment_type.govukpay.label;
      }
    }
    const alert = await this.alertController.create({
      header: this.govuktitle,
      message: 'Enter the amount',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Continue',
          role: 'OK',
          handler: async (res) => {
            await this.saveGovpay(res.amount);
          },
        },
      ],
      inputs: [
        {
          type: 'number',
          name: 'amount',
          placeholder: 'Amount...',
          value: this.totalOutstandingWithoutSymbol,
        },
      ],
    });

    await alert.present();
  }

  async saveGovpay(amount) {
    let obj = {
      amount: Math.floor(amount * 100),
      reference: this.caseList[0]?.ref ? this.caseList[0]?.ref : '',
      description: this.caseList[0]?.offense ? this.caseList[0]?.offense : '',
      email: this.debtorDetails?.debtor_email
        ? this.debtorDetails?.debtor_email
        : '',
      cardholder_name: this.debtorDetails?.debtor_name
        ? this.debtorDetails?.debtor_name
        : '',
      line1: this.debtorDetails?.addresses[0]?.address_ln1
        ? this.debtorDetails?.addresses[0]?.address_ln1
        : '',
      line2: this.debtorDetails?.addresses[0]?.address_ln2
        ? this.debtorDetails?.addresses[0]?.address_ln2
        : '',
      city: this.debtorDetails?.addresses[0]?.address_town
        ? this.debtorDetails?.addresses[0]?.address_town
        : '',
      postcode: this.debtorDetails?.addresses[0]?.address_postcode
        ? this.debtorDetails?.addresses[0]?.address_postcode
        : '',
      CaseID: this.firstCaseId,
      SummonsNo: this.caseList[0]?.ref
        ? this.caseList[0]?.ref
        : this.firstCaseId,
      AccountNo: this.caseList[0]?.cl_ref
        ? this.caseList[0]?.cl_ref
        : this.firstCaseId,
      return_url: window.location.href,
    };
    this.commonService.showLoader();
    this.caseService.getGovUKPayLink(obj).subscribe((res: any) => {
      console.log(res);
      if (res.result) {
        if (res.data?.payment_url) {
          var win = window.open(res.data.payment_url, '_self');
          if (win) {
            win.focus();
          }
        }
      } else {
        this.commonService.showToast(res['message'], 'danger');
      }
      this.commonService.dismissLoader();
    });
  }

  async goToPaymentAmountPopup(type = '') {
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    let title = 'Lateral Payment Portal';
    if (portalSettings?.payment_type) {
      let payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
      if (payment_type?.usaepay && payment_type?.usaepay?.label) {
        title = payment_type.usaepay.label;
      }
    }
    const alert = await this.alertController.create({
      header: title,
      message: 'Enter the amount',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Continue',
          role: 'OK',
          handler: async (res) => {
            if (type == 'usaepay'){
              await this.goToUSAepay(res.amount);
            } else if(type == 'payroc') {
              await this.goToPayRoc(res.amount);
            } else if (type == 'gocardless') {
              await this.goToGoCardless(res.amount);
            } else if (type == 'acquired') {
              await this.goToAcquired(res.amount);
            }
          },
        },
      ],
      inputs: [
        {
          type: 'number',
          name: 'amount',
          placeholder: 'Amount...',
          value: this.totalOutstandingWithoutSymbol,
        },
      ],
    });

    await alert.present();
  }

  async goToCreatePaymentPlan2(amount = 0, freq = 0, customPlan = 0) {
    const modal = await this.modalController.create({
      component: ArrangementPage,
      componentProps: {
        caseId: this.firstCaseId,
        currentCase: this.firstCaseDetails,
        totalOutstanding: this.totalOutstandingWithoutSymbol,
        isGroupArrangement: this.caseList.length > 1 ? true : false,
        debtorDetails: this.debtorDetails,
        paymentGatewayList: this.paymentGatewayList,
        amount,
        freq,
        customPlan,
      },
    });
    // return await modal.present();
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      if (data.saved) {
        this.getCaseDetails();
      }
    }
  }

  async arrangementActionSheet() {
    if (
      this.availablePaymentPlanOptions &&
      this.availablePaymentPlanOptions.length
    ) {
      let buttons = [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
      ];
      buttons = buttons.concat(this.availablePaymentPlanOptions);
      const actionSheet = await this.actionSheetController.create({
        header: 'Available Payment Option',
        cssClass: 'my-custom-class',
        buttons: buttons,
      });
      await actionSheet.present();
    } else {
      this.mapToRequiredPaymentPlanOption();
    }
  }

  getPaymentPlanOptions() {
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    const options = {
      3: 'week',
      4: 'month',
      6: 'fortnight',
    };
    let currentPlan;
    this.availablePaymentPlanOptions = [];
    if (portalSettings.arrangement?.use_new_recurring_payment) {
      this.use_new_recurring_payment = true;
    }
    if (portalSettings?.arrangement && portalSettings.arrangement?.plan) {
      let plans = portalSettings?.arrangement.plan
        ? portalSettings?.arrangement.plan
        : [];
      let total = this.totalOutstandingWithoutSymbol;
      currentPlan = plans.find((plan) => {
        if (plan.type == 'custom') {
          if (plan.range == 'SMALLER_EQUALS' && total <= plan.max) {
            return plan;
          } else if (total >= plan.min && plan.range == 'BIGGER_EQUALS') {
            return plan;
          } else if (total >= plan.min && total <= plan.max) {
            return plan;
          }
        }
      });
      if (!currentPlan && plans.find((plan) => plan.type == 'default')) {
        currentPlan = plans.find((plan) => plan.type == 'default');
      }
      const UsaePay_processing_fee_amount =
        this.caseList[0].UsaePay_processing_fee_amount;
      if (currentPlan?.option && currentPlan.option.length) {
        currentPlan.option.forEach((arr) => {
          let amount = parseFloat(
            (this.totalOutstandingWithoutSymbol / arr.limit).toFixed(2)
          );
          let balance = total - amount * arr.limit;
          balance = Math.round(balance * 100) / 100;
          if (balance > 0) {
            amount = Math.round((amount + balance) * 100) / 100;
          }
          let scheduledAmount = (
            amount + parseFloat(UsaePay_processing_fee_amount)
          ).toFixed(2);
          // let text = "Pay " + this.currency_symbol + amount  + " per " + options[arr.freq]+ " for " + arr.limit + " " + options[arr.freq]+"s";
          let text =
            'Pay ' +
            this.currency_symbol +
            scheduledAmount +
            ' per ' +
            options[arr.freq] +
            ' for ' +
            arr.limit +
            ' ' +
            options[arr.freq] +
            's';
          // if(balance < 0){
          //   text += " (Last payment will be of " + this.currency_symbol + Math.round(((amount + parseFloat(UsaePay_processing_fee_amount)) + balance) * 100)/100 + ")";
          // }
          // if(parseFloat(UsaePay_processing_fee_amount) > 0){
          //   text += " (Includes processing fee)";
          // }
          this.availablePaymentPlanOptions.push({
            text,
            handler: (ar) => {
              this.mapToRequiredPaymentPlanOption(amount, arr.freq, 1);
            },
          });
        });
      }
      if (
        portalSettings.arrangement?.customized_option &&
        portalSettings.arrangement?.customized_option == 'yes'
      ) {
        this.availablePaymentPlanOptions.push({
          text: 'Proceed with custom option',
          handler: (ar) => {
            this.mapToRequiredPaymentPlanOption();
          },
        });
      }
    }
  }

  async mapToRequiredPaymentPlanOption(amount = 0, freq = 0, customPlan = 0) {
    if (this.use_new_recurring_payment) {
      this.goToNewCardPaymentPlan(amount, freq, customPlan);
    } else {
      this.goToCreatePaymentPlan2(amount, freq, customPlan);
    }
  }

  async goToNewCardPaymentPlan(amount = 0, freq = 0, customPlan = 0) {
    const modal = await this.modalController.create({
      component: RecurringPaymentPlanPage,
      componentProps: {
        amount,
        freq,
        customPlan,
        caseId: this.firstCaseId,
        totalOutstandingWithSymbol: this.totalOutstanding,
        debtorDetails: this.debtorDetails,
        caseDetails: this.firstCaseDetails,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      if (data.saved) {
        this.getCaseDetails();
      }
    }
  }

  formatDate(inputDateString,) {
    let settings = JSON.parse(localStorage.getItem('portal_settings'));
    if(settings?.datepicker_date_format){
      this.datepicker_date_format = settings.datepicker_date_format;
    }
    const inputDate = moment(inputDateString);
    const formattedDate = inputDate.format(this.datepicker_date_format);
    return formattedDate;
  }
}
