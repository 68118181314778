import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeGuard } from '../guards/home.guard';

import { MasterPage } from './master.page';

const routes: Routes = [
  {
    path: '',
    component: MasterPage,
    loadChildren: () => import('./details/details.module').then( m => m.DetailsPageModule),
    children: [
      {
        path: 'details',
        loadChildren: () => import('./details/details.module').then( m => m.DetailsPageModule)
      },
      {
        path: '',
        loadChildren: () => import('./details/details.module').then( m => m.DetailsPageModule)
        // redirectTo: 'details',
        // pathMatch: 'full'
      },
      {
        path: 'mobipaid',
        loadChildren: () => import('./mobipaid/mobipaid.module').then( m => m.MobipaidPageModule)
      },
      {
        path: 'payment-plan',
        loadChildren: () => import('./payment-plan/payment-plan.module').then( m => m.PaymentPlanPageModule)
      },
      {
        path: 'contact-form',
        loadChildren: () => import('./contact-form/contact-form.module').then( m => m.ContactFormPageModule)
      },
      {
        path: 'govukpay',
        loadChildren: () => import('./govukpay/govukpay.module').then( m => m.GovukpayPageModule)
      },
      {
        path: 'usaepay',
        loadChildren: () => import('./usaepay/usaepay.module').then( m => m.USAepayPageModule)
      },
      {
        path: 'payroc',
        loadChildren: () => import('./payroc/payroc.module').then( m => m.PayRocPageModule)
      },
      {
        path: 'arrangement',
        loadChildren: () => import('./arrangement/arrangement.module').then( m => m.ArrangementPageModule)
      },
      {
        path: 'authorize-card',
        loadChildren: () => import('./authorize-card/authorize-card.module').then( m => m.AuthorizeCardPageModule)
      },
      {
        path: 'recurring-payment-plan',
        loadChildren: () => import('./recurring-payment-plan/recurring-payment-plan.module').then( m => m.RecurringPaymentPlanPageModule)
      },
      {
        path: 'gocardless',
        loadChildren: () =>
          import('./gocardless/gocardless.module').then(
            (m) => m.GoCardlessPageModule
          ),
      },
      {
        path: 'acquired',
        loadChildren: () =>
          import('./acquired/acquired.module').then(
            (m) => m.AcquiredPageModule
          ),
      },
    ],
  },
  { path: '**', redirectTo: 'details', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MasterPageRoutingModule { }
