import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ArrangementPageRoutingModule } from './arrangement-routing.module';

import { ArrangementPage } from './arrangement.page';
// import { UpdateArrangementModalPageModule } from '../update-arrangement-modal/update-arrangement-modal.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ArrangementPageRoutingModule,
    // UpdateArrangementModalPageModule,
  ],
  declarations: [ArrangementPage],
  // exports: [ArrangementModalPage],
  // entryComponents: [ArrangementModalPage],
})
export class ArrangementPageModule {}
