import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.page.html',
  styleUrls: ['./contact-form.page.scss'],
})
export class ContactFormPage implements OnInit {

  @Input() caseId;
  note: string;
  saveBtnDisabled = false;
  constructor(
    private modalCtrl: ModalController,
    private caseService: CaseService,
    private commonService: CommonService,

  ) { }

  ngOnInit() {
  }

  async dismiss() {
    this.modalCtrl.dismiss({
      saved: false
    });
  }
  cancel() {
    this.dismiss();
  }
  save() {
    if (this.note) {
      this.saveBtnDisabled = true;
      const data = {
        note: this.note,
        caseid: this.caseId,
      };
      this.caseService.contactForm(data).subscribe((res: any) => {
        if(res.result){
          this.commonService.showToast(res['data']['message'],'success');
          this.modalCtrl.dismiss({
            saved: true
          });
        } else {
          this.commonService.showToast(res['message'],'danger');
          this.dismiss();
        }
        this.saveBtnDisabled = false;
      });
    }
  }
}
