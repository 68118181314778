import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ArrangementPage } from './arrangement.page';

const routes: Routes = [
  {
    path: '',
    component: ArrangementPage,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ArrangementPageRoutingModule {}
