import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DetailsPageRoutingModule } from './details-routing.module';

import { DetailsPage } from './details.page';
import { MobipaidPageModule } from '../mobipaid/mobipaid.module';
import { PaymentPlanPageModule } from '../payment-plan/payment-plan.module';
import { ContactFormPageModule } from '../contact-form/contact-form.module';
import { ArrangementPageModule } from '../arrangement/arrangement.module';
import { RecurringPaymentPlanPageModule } from '../recurring-payment-plan/recurring-payment-plan.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DetailsPageRoutingModule,
    MobipaidPageModule,
    PaymentPlanPageModule,
    ContactFormPageModule,
    RecurringPaymentPlanPageModule,
    ArrangementPageModule
  ],
  declarations: [DetailsPage]
})
export class DetailsPageModule {}
