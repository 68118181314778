import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
declare var gocardless: any;

@Component({
  selector: 'app-gocardless',
  templateUrl: './gocardless.page.html',
  styleUrls: ['./gocardless.page.scss'],
})
export class GoCardlessPage implements OnInit {
  @Input() caseId;
  @Input() responseData;
  title = 'Lateral Payment Portal';
  gocardless: any;
  GoCardlessForm: FormGroup;
  saveBtnDisabled = false;
  isConfigured = false;
  amount = 0;
  is_iban = false;
  fieldLabels = {};
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private caseService: CaseService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.saveBtnDisabled = false;
    this.amount = this.responseData?.net_amount;
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.payment_type) {
      let payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
      if (payment_type?.gocardless && payment_type?.gocardless?.label) {
        this.title = payment_type.gocardless.label;
      }
    }
    if (portalSettings?.fieldLabels) {
      this.fieldLabels = portalSettings.fieldLabels;
    }
    this.isConfigured = true;
    this.initForm();
  }

  initForm() {
    this.GoCardlessForm = this.formBuilder.group({
      amount: [this.amount ? this.amount : 0, [Validators.required]],
      sort_code: ['', [Validators.required]],
      is_iban: false,
      account_number: ['', [Validators.required]],
    });
  }

  isIBANChecked(event) {
    let sort_code = this.GoCardlessForm.controls.sort_code;
    if (event.detail.checked) {
      this.is_iban = true;
      sort_code.setValidators([]);
      sort_code.updateValueAndValidity();
    } else {
      this.is_iban = false;
      sort_code.setValidators([Validators.required]);
      sort_code.updateValueAndValidity();
    }
  }

  proceedPayment() {
    if (this.GoCardlessForm.valid) {
      this.saveBtnDisabled = true;
      let obj = this.GoCardlessForm.value;
      obj['gateway_id'] = this.responseData.id;
      this.caseService
        .makeGoCardlessPayment(this.caseId, obj)
        .subscribe((res: any) => {
          this.saveBtnDisabled = false;
          this.commonService.dismissLoader();
          if (res.result) {
            this.commonService.showToast(res['message'], 'success');
            this.modalCtrl.dismiss({
              saved: true,
            });
          } else {
            this.commonService.showToast(res['message'], 'danger');
          }
        });
    }
  }

  async dismiss() {
    this.modalCtrl.dismiss({
      saved: false,
    });
  }
}
