import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-master',
  templateUrl: './master.page.html',
  styleUrls: ['./master.page.scss'],
})
export class MasterPage implements OnInit {

  title = 'Lateral Debtor Portal';

  constructor(
    private alertCtrl: AlertController,
  ) { }

  ngOnInit() {
    let settings = JSON.parse(localStorage.getItem('portal_settings'));
    if(settings?.portal_details?.title){
      this.title = settings.portal_details.title;
    }
  }

  async confirmLogout() {
    const alert = await this.alertCtrl.create({
      header: 'Confirm Logout!',
      message: 'Are you sure you want to logout?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
          }
        },
        {
          text: 'Logout',
          handler: () => {
            localStorage.removeItem('remote_token');
            let from_viewmycase = parseInt(localStorage.getItem('from_viewmycase'));
            if(from_viewmycase){
              window.location.href = '/viewmycase';
            } else {
              window.location.href = '/login?dynamic=1';
            }
          }
        }
      ]
    });
    await alert.present();
  }
}
