import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
// import { CaseActionService } from 'src/app/services/case-action.service';
// import { UpdateArrangementModalPage } from '../update-arrangement-modal/update-arrangement-modal.page';
import { AuthorizeCardPage } from '../authorize-card/authorize-card.page';
import * as moment from 'moment';
// import { DatabaseService, StorageService } from 'src/app/services';
import { CalendarModal, CalendarModalOptions } from 'ion2-calendar';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
import { CardService } from 'src/app/services/card.service';

@Component({
  selector: 'app-arrangement',
  templateUrl: './arrangement.page.html',
  styleUrls: ['./arrangement.page.scss'],
})
export class ArrangementPage implements OnInit {
  @Input() caseId;
  @Input() responseData;
  @Input() debtorDetails;
  @Input() totalOutstandingWithSymbol;
  @Input() currentCase;
  @Input() paymentGatewayList;
  @Input() amount;
  @Input() freq;
  @Input() customPlan;
  debtorId;
  outstanding;
  arrangementForm: FormGroup;
  is_iban = false;
  GoCardlessForm: FormGroup;
  // tslint:disable: max-line-length
  frequency = [
    { id: '2', label: 'One time final payment' },
    { id: '3', label: 'Weekly By day of the week' },
    { id: '4', label: 'Monthly by date' },
    { id: '5', label: 'Monthly by day' },
    { id: '6', label: 'Fortnightly' },
    { id: '7', label: '4 weekly' },
  ];
  paymentMethods = [
    { id: 1, label: 'Cash' },
    { id: 2, label: 'Cheque' },
    { id: 4, label: 'Credit card' },
    { id: 5, label: 'Debit Card' },
    { id: 8, label: 'BACS' },
  ];
  arrangementObj: any = { show: true };
  currentArrangementString = '';
  currArrangement;
  arrangementMode = 'make';
  savedCards = [];
  activeArrangements: any = {
    currentArrangements: {
      show: true,
    },
    scheduleArrangements: {
      show: true,
      data: null,
    },
  };
  inActiveArrangements: any = {
    show: false,
  };
  frequencies = [
    '',
    '',
    'One time final payment',
    'Weekly by day of the week',
    'Monthly by date',
    'Monthly by day - e.g. "1st friday',
    'Fortnightly',
    '4 weekly',
    'Last day of the month',
    'First day of the month',
    'Last work day of the month',
    'Last Friday of the month',
  ];
  updatedIndex = -1;
  networkStatus;
  isDetailsPage;
  isGroupArrangement = false;
  baseOutstanding;
  groupArrId;
  date;
  saveBtnDisabled = false;
  startDateBuffer = 0;
  isActiveArrangementPresent = false;
  dateFormat = 'DD-MM-YYYY';
  currency_symbol;
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private caseService: CaseService,
    private cardService: CardService,
    private alertController: AlertController,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.initForm();
  }
  async ionViewWillEnter() {
    this.getActiveArrangements();
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      saved: false,
    });
  }
  async openCalendar(input) {
    console.log(input);
    this.date = input;
    const options: CalendarModalOptions = {
      title: '',
      closeIcon: true,
      doneIcon: true,
    };
    let toDate = new Date();
    toDate.setDate(toDate.getDate() + this.startDateBuffer - 1);
    if(this.customPlan){
      options.from = new Date();
      options.to = toDate;
    }
    const myCalendar = await this.modalCtrl.create({
      component: CalendarModal,
      componentProps: {
        options: options,
      },
    });

    myCalendar.onDidDismiss().then((response) => {
      console.log(this.date);
      const date = this.date;
      console.log(response);
      if (response.role == 'done') {
        this.arrangementForm.controls[date].patchValue(
          moment(response.data.dateObj).format('YYYY-MM-DD'),
          { onlySelf: true }
        );
        this.arrangementForm.controls[date].updateValueAndValidity();
      }
    });
    await myCalendar.present();
  }
  initForm() {

    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if(portalSettings.payment_method_list && Object.values(portalSettings.payment_method_list)){
      this.paymentMethods = [];
      const payment_method_list = portalSettings.payment_method_list;
      Object.keys(payment_method_list).forEach(key => {
        this.paymentMethods.push({ id: parseInt(key), label: payment_method_list[key] });
      });
    }
    if (portalSettings?.arrangement && portalSettings.arrangement?.start_date_buffer) {
      this.startDateBuffer = portalSettings.arrangement.start_date_buffer ?  parseInt(portalSettings.arrangement.start_date_buffer):  0;
    }

    if(this.currentCase?.arranagement 
      && this.currentCase.arranagement?.current_arrangement 
      && this.currentCase.arranagement.current_arrangement.length) {
      this.isActiveArrangementPresent = this.currentCase.arranagement.current_arrangement.length > 0 ? true : false;
    }

    this.arrangementForm = this.formBuilder.group({
      selectedLinkCaseIds: [[]],
      frequency: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      ref_amount: [this.outstanding, [Validators.required]],
      method: ['', []],
      start: ['', [Validators.required]],
      note: ['', []],
      differentFirstPayment: [false, []],
      firstPaymentAmount: [''],
      firstPaymentDate: [''],
      repeatPayment: [false, ''],
      payment_method: [],
      payment_card_list: [],
    });
    
    this.GoCardlessForm = this.formBuilder.group({
      amount: [this.amount ? this.amount : 0, [Validators.required]],
      sort_code: ['', [Validators.required]],
      is_iban: false,
      account_number: ['', [Validators.required]],
    });
    
    if(this.customPlan){
      this.arrangementForm.patchValue({ 
        ref_amount: this.outstanding+"", 
        amount: this.amount+"", 
        frequency: this.freq+"", 
        start: moment().format('YYYY-MM-DD'),
        selectedLinkCaseIds: this.currentCase.linked_cases.map(c => c.id)
      });
    }
  }
  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Archive Arrangement',
      message: 'Are you sure want to archive arrangement?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Archive',
          role: 'OK',
          handler: async (res) => {
            this.arrangementObj = {
              note: res.note ? res.note : '',
              mode: 'archive',
            };
            await this.submit_form('edit');
          },
        },
      ],
      inputs: [
        {
          type: 'textarea',
          name: 'note',
          placeholder: 'Note...',
        },
      ],
    });

    await alert.present();
  }
  async save() {
    if (this.arrangementForm.valid) {
      // this.storageService.set('is_case_updated', true);
      this.arrangementObj = {
        freq: this.arrangementForm.value.frequency,
        amount: this.arrangementForm.value.amount,
        ref_amount: this.arrangementForm.value.ref_amount,
        method: this.arrangementForm.value.method,
        source: this.arrangementForm.value.source,
        start: moment(this.arrangementForm.value.start).format('YYYY-MM-DD'),
        different_first_payment:
          this.arrangementForm.value.differentFirstPayment,
        first_amount: this.arrangementForm.value.firstPaymentAmount,
        first_date: this.arrangementForm.value.firstPaymentDate
          ? moment(this.arrangementForm.value.firstPaymentDate).format(
              'YYYY-MM-DD'
            )
          : '',
        note: this.arrangementForm.value.note,
        mode: this.arrangementMode,
        repeatPayment: this.arrangementForm.value.repeatPayment,
        payment_method: this.arrangementForm.value.payment_method,
        payment_card_list: this.arrangementForm.value.payment_card_list,
      };
      let linkedCases = this.arrangementForm.value.selectedLinkCaseIds;
      let type = 'edit';
      if (linkedCases.length) {
        linkedCases.push(this.caseId);
        let uniqueLinkedCases = [];
        linkedCases.forEach(element => {
          if(element && uniqueLinkedCases.indexOf(element) == -1){
            uniqueLinkedCases.push(element);
          }
        });
        this.arrangementObj.cases = uniqueLinkedCases;
        this.arrangementObj.is_group = 1;
        type = 'group_arrangement';
      }
      await this.submit_form(type);
    } else {
      this.arrangementObj = {};
    }
  }
  submit_form(type) {
    this.commonService.showLoader();
    this.caseService
      .createArrangement(type, this.caseId, this.arrangementObj)
      .subscribe((res: any) => {
        this.saveBtnDisabled = false;
        this.commonService.dismissLoader();
        console.log(res);
        if (res.status == 200) {
          this.commonService.showToast('Action Performed Successfully!', 'success');
          this.modalCtrl.dismiss({
            saved: true,
          });
        } else {
          this.commonService.showToast(res['message'], 'danger');
          this.dismiss();
        }
      });
  }
  differentFirstPaymentChanged(event) {
    let firstPaymentAmount =
      this.arrangementForm.controls['firstPaymentAmount'];
    let firstPaymentDate = this.arrangementForm.controls['firstPaymentDate'];
    if (event.detail.checked) {
      firstPaymentAmount.setValidators([Validators.required]);
      firstPaymentDate.setValidators([Validators.required]);
      firstPaymentAmount.updateValueAndValidity();
      firstPaymentDate.updateValueAndValidity();
    } else {
      firstPaymentAmount.setValidators([]);
      firstPaymentDate.setValidators([]);
      firstPaymentAmount.updateValueAndValidity();
      firstPaymentDate.updateValueAndValidity();
    }
  }
  repeatPaymentSelected(event) {
    let payment_card_list = this.arrangementForm.controls.payment_card_list;
    let payment_method = this.arrangementForm.controls.payment_method;
    if (event.detail.checked) {
      payment_card_list.setValidators([Validators.required]);
      payment_card_list.updateValueAndValidity();
      payment_method.setValidators([Validators.required]);
      payment_method.updateValueAndValidity();
      if (this.paymentGatewayList.length > 0) {
        payment_method.setValue(this.paymentGatewayList[0].key);
        this.getCards();
      }
    } else {
      payment_card_list.clearValidators();
      payment_card_list.reset();
      payment_card_list.updateValueAndValidity();
      payment_method.clearValidators();
      payment_method.reset();
      payment_method.updateValueAndValidity();
    }
  }
  setLatestCard() {
    if (
      this.arrangementForm.value.payment_method == 'sagePay' &&
      this.savedCards.length > 0
    ) {
      let payment_card_list = this.arrangementForm.controls.payment_card_list;
      payment_card_list.setValue(
        this.savedCards[this.savedCards.length - 1].key
      );
    }
  }
  getCards() {
    if (this.arrangementForm.value.payment_method) {
      let method = '';
      let id = this.debtorId;
      const linkedCases = this.arrangementForm.value.selectedLinkCaseIds;
      console.log(linkedCases);
      let grpSelCases = '';
      if (linkedCases.length) {
        method = 'group';
        linkedCases.push(this.caseId);
        linkedCases.forEach((element) => {
          grpSelCases += element + '@@';
        });
        id = grpSelCases;
      }
      this.cardService
        .getSavedCards(id, this.arrangementForm.value.payment_method, method)
        .subscribe((res) => {
          if (res) {
            const cards = [];
            Object.keys(res).forEach((element) => {
              cards.push({ key: element, value: res[element].last_digits });
            });
            this.savedCards = cards;
            setTimeout(() => {
              this.setLatestCard();
            }, 100);
          }
        });
    }
  }
  async manageCards() {
    const manageCards = await this.modalCtrl.create({
      component: AuthorizeCardPage,
      componentProps: {
        caseId: this.isGroupArrangement ? this.groupArrId : this.caseId,
        debtorId: this.debtorId,
        isGroupArr: this.isGroupArrangement,
        selectedCases: this.arrangementForm.value.selectedLinkCaseIds,
        paymentMethod: this.arrangementForm.value.payment_method,
        debtorDetails: this.debtorDetails,
      },
    });
    manageCards.onDidDismiss().then((response) => {
      if (response) {
        this.getCards();
      }
    });
    await manageCards.present();
  }
  toggleShow(object) {
    object.show = !object.show;
  }
  isShown(object) {
    return object.show;
  }
  async getActiveArrangements() {
    if(localStorage.getItem('dateFormat')) this.dateFormat = localStorage.getItem('dateFormat');
    this.currency_symbol = this.currentCase.currency_symbol;
    this.baseOutstanding = this.outstanding = this.currentCase.d_outstanding.replace(this.currentCase.currency_symbol,"");
    this.onLinkCaseSelectChange(null); // To handle the linked case total outstanding.
    this.arrangementForm.patchValue({ ref_amount: this.outstanding });

    if (this.currentCase.arranagement) {
      this.currArrangement = this.currentCase.arranagement.current_arrangement;
    }
    if (this.currentCase.debtorid) {
      this.debtorId = this.currentCase.debtorid;
    }

    if (this.currArrangement)
      this.currArrangement = this.currArrangement.find(
        (data) => data.active == 1
      );

    this.makeCurrentArrangementString();
    if (this.currArrangement) {
      this.arrangementMode = 'archive_make';
      // if (this.isGroupArrangement) {
      //   this.activeArrangements.scheduleArrangements.data = Object.values(response.group_schedules);
      // } else {
      this.activeArrangements.scheduleArrangements.data =
        this.currentCase.arranagement.arrangement_schedule.reverse();
      // }

      this.activeArrangements.scheduleArrangements.data.forEach((element,index) => {
        this.activeArrangements.scheduleArrangements.data[index].date = moment(
          element.date
        ).format(this.dateFormat);
        let amount_paid;
        if(element.arr_balance == 0.00 && element.payment_action.id == -16){
            amount_paid = element.amount;
        }else if((element.arr_balance > 0.00  && element.payment_action.id == -12) || (element.arr_balance < 0.00  && element.payment_action.id == -8)){
            amount_paid = element.amount + element.arr_balance;
        }else{
            amount_paid = '0.00';
        }
        this.activeArrangements.scheduleArrangements.data[index].arr_balance = amount_paid;
      });
    }
    // });

    // this.currentCase.linked_cases = [];
  }
  makeCurrentArrangementString() {
    this.currentArrangementString = '';

    let debtor_field =  'defendant';
    let currency_symbol = '&pound;';
    try {
      if(this.currentCase.scheme.scheme_manager.debtor_field){
        debtor_field = this.currentCase.scheme.scheme_manager.debtor_field;
      }
      currency_symbol = this.currentCase.currency_symbol;
    } catch (error) {}
    if (this.currArrangement) {
      this.currentArrangementString += `The ${debtor_field} agreed to pay `;
      if (
        parseInt(this.currArrangement.first_amount, 10) > 0 &&
        this.currArrangement.first_date
      ) {
        this.currentArrangementString += `initial payment of <strong>${currency_symbol}${
          this.currArrangement.first_amount
        }
        </strong> due on <strong>${moment(
          this.currArrangement.first_date
        ).format(this.dateFormat)}</strong> followed by `;
      }
      this.currentArrangementString += `<strong>${currency_symbol}${
        this.currArrangement.amount
      }</strong> with a <strong>
      ${this.frequencies[this.currArrangement.freq]}</strong> on <strong>
      ${moment(this.currArrangement.start).format(this.dateFormat)}</strong>.</p>`;
      if (this.isGroupArrangement) {
        this.currentArrangementString += `<div>Selected Cases:  ${JSON.parse(
          this.currArrangement.caseids
        ).join()}</div>`;
      }
    } else {
      this.currentArrangementString += `The case ${this.caseId} has no arrangement set.`;
    }
  }
  getInactiveArrangements() {
    // this.caseActionService.getInactiveArrangements(this.caseId).subscribe((response: any) => {
    //   this.inActiveArrangements.data = response.data.data;
    //   this.frequencies = response.data.frequencies;
    //   let freqNew = [];
    //   if (this.frequencies) {
    //     Object.entries(this.frequencies).forEach((val) => {
    //       const obj = { id: val[0], label: val[1] };
    //       freqNew.push(obj);
    //     });
    //     this.frequency = freqNew;
    //   }
    //   this.makeCurrentArrangementString();
    // });
  }
  async update(arrangement, index) {
    // const updateArrangement = await this.modalCtrl.create({
    //   component: UpdateArrangementModalPage,
    //   componentProps: {
    //     caseId: this.isGroupArrangement ? this.groupArrId : this.caseId,
    //     scheduleArrangement: arrangement,
    //     isGroupArr: this.isGroupArrangement
    //   }
    // });
    // updateArrangement.onDidDismiss()
    //   .then((response) => {
    //     this.getActiveArrangements();
    //   });
    // await updateArrangement.present();
  }

  // To handle the group arrangement
  onLinkCaseSelectChange(event) {
    const linked = this.currentCase.linked_cases.filter(
      (lc) =>
        this.arrangementForm.value.selectedLinkCaseIds.indexOf(lc.id) != -1
    );
    const linkedCasesTotalBalance =
      parseFloat(this.baseOutstanding) +
      linked.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.d_outstanding.replace(currentValue.currency_symbol, ""));
      }, 0);
    this.outstanding = linkedCasesTotalBalance;
    this.arrangementForm.patchValue({ ref_amount: linkedCasesTotalBalance });
  }
  
  isIBANChecked(event) {
    let sort_code = this.GoCardlessForm.controls.sort_code;
    if (event.detail.checked) {
      this.is_iban = true;
      sort_code.setValidators([]);
      sort_code.updateValueAndValidity();
    } else {
      this.is_iban = false;
      sort_code.setValidators([Validators.required]);
      sort_code.updateValueAndValidity();
    }
  }
}
