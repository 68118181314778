import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
declare var usaepay: any;
@Component({
  selector: 'app-usaepay',
  templateUrl: './usaepay.page.html',
  styleUrls: ['./usaepay.page.scss'],
})
export class USAepayPage implements OnInit {
  @Input() caseId;
  @Input() responseData;
  @Input() debtorDetails;
  @Input() totalOutstandingWithSymbol;
  @Input() caseDetails;
  title = 'Lateral Payment Portal';
  USAePayForm: FormGroup;
  saveBtnDisabled = false;
  usaepayclient;
  paymentCard;
  usaepay: any;
  jsUrl = '';
  publickey = '';
  isConfigured = false;
  amount = 0;
  fieldLabels = {};
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private caseService: CaseService,
    private commonService: CommonService
  ) {}

  loadScript(url: string) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }

  ngOnInit() {
    this.saveBtnDisabled = true;
    this.amount = this.responseData?.net_amount;
    this.jsUrl = 'https://sandbox.usaepay.com/js/v1/pay.js';
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.payment_type) {
      let payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
      if (payment_type?.usaepay && payment_type?.usaepay?.label) {
        this.title = payment_type.usaepay.label;
      }
    }
    if (portalSettings?.fieldLabels) {
      this.fieldLabels = portalSettings.fieldLabels;
    }
    if (
      portalSettings?.usaepay &&
      portalSettings?.usaepay?.jsUrl &&
      portalSettings?.usaepay?.publickey
    ) {
      this.jsUrl = portalSettings.usaepay.jsUrl;
      this.publickey = portalSettings.usaepay.publickey;
      this.isConfigured = true;
    }
    this.loadScript(this.jsUrl)
      .then(() => {
        this.usaepayclient = new usaepay.Client(this.publickey);
        this.paymentCard = this.usaepayclient.createPaymentCardEntry();
        this.paymentCard.generateHTML();
        this.paymentCard.addHTML('paymentCardContainer');
        this.paymentCard.addEventListener('error', (errorMessage) => {
          var errorContainer = document.getElementById(
            'paymentCardErrorContainer'
          );
          errorContainer.textContent = errorMessage;
        });
        setTimeout(() => (this.saveBtnDisabled = false), 3000);
      })
      .catch((error) => {
        console.error(error);
      });

    this.initForm();
  }

  initForm() {
    const addresses = this.debtorDetails?.addresses.filter(
      (add) => add.address_main == 1
    ) || [{}];
    this.USAePayForm = this.formBuilder.group({
      amount: [this.amount ? this.amount : 0, [Validators.required]],
      debtor_first_name: [
        this.debtorDetails?.debtor_1_name
          ? this.debtorDetails?.debtor_1_name
          : '',
        [Validators.required],
      ],
      debtor_last_name: [
        this.debtorDetails?.debtor_1_surname
          ? this.debtorDetails?.debtor_1_surname
          : '',
        [Validators.required],
      ],
      description: [''],
      address_ln1: [
        addresses[0]?.address_ln1 ? addresses[0]?.address_ln1 : '',
        [Validators.required],
      ],
      address_ln2: [
        addresses[0]?.address_ln2 ? addresses[0]?.address_ln2 : '',
        [],
      ],
      address_ln3: [
        addresses[0]?.address_ln3 ? addresses[0]?.address_ln3 : '',
        [],
      ],
      town: [
        addresses[0]?.address_town ? addresses[0]?.address_town : '',
        [Validators.required],
      ],
      address_postcode: [
        addresses[0]?.address_postcode ? addresses[0]?.address_postcode : '',
        [Validators.required],
      ],
    });
  }

  async generatePaymentKey() {
    var errorContainer = document.getElementById('paymentCardErrorContainer');
    this.saveBtnDisabled = true;
    this.commonService.showLoader();
    try {
      const result = await this.usaepayclient.getPaymentKey(this.paymentCard);
      if (result.error) {
        errorContainer.textContent = result.error.message;
        errorContainer.classList.remove('d-none');
        this.saveBtnDisabled = false;
        this.commonService.dismissLoader();
      } else {
        this.proceedPayment(result);
      }
    } catch (error) {
      this.saveBtnDisabled = false;
      this.commonService.dismissLoader();
      this.commonService.showToast(error, 'danger');
    }
  }

  proceedPayment(paymentKey) {
    if (this.USAePayForm.valid) {
      this.saveBtnDisabled = true;
      let obj = this.USAePayForm.value;
      obj['payment_key'] = paymentKey;
      obj['gateway_id'] = this.responseData.id;
      this.caseService
        .makeUSAePayment(this.caseId, obj)
        .subscribe((res: any) => {
          this.saveBtnDisabled = false;
          this.commonService.dismissLoader();
          if (res.result) {
            this.commonService.showToast(res['data']['message'], 'success');
            this.modalCtrl.dismiss({
              saved: true,
            });
          } else {
            this.commonService.showToast(res['message'], 'danger');
            this.dismiss();
          }
        });
    }
  }

  async dismiss() {
    this.modalCtrl.dismiss({
      saved: false,
    });
  }

  cancel() {
    this.dismiss();
  }
}
