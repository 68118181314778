import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CalendarModal, CalendarModalOptions } from 'ion2-calendar';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';
@Component({
  selector: 'app-recurring-payment-plan',
  templateUrl: './recurring-payment-plan.page.html',
  styleUrls: ['./recurring-payment-plan.page.scss'],
})
export class RecurringPaymentPlanPage implements OnInit {
  @Input() caseId;
  @Input() debtorDetails;
  @Input() totalOutstandingWithSymbol;
  @Input() caseDetails;

  @Input() amount;
  @Input() freq;
  @Input() customPlan;
  title = 'Lateral Payment Portal';
  recurringPaymentPlanForm: FormGroup;
  saveBtnDisabled = false;
  date;
  fieldLabels = {};
  frequency = [
    { id: '2', label: 'One time final payment' },
    { id: '3', label: 'Weekly By day of the week' },
    { id: '4', label: 'Monthly by date' },
    { id: '5', label: 'Monthly by day' },
    { id: '6', label: 'Fortnightly' },
    { id: '7', label: '4 weekly' },
  ];
  outstanding;
  datemin = moment().format('YYYY-MM-DD');
  datemax = moment().add('100', 'years').format('YYYY-MM-DD');
  startDateBuffer = 0;
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private caseService: CaseService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.payment_type) {
      let payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
      if (payment_type?.usaepay && payment_type?.usaepay?.label) {
        this.title = payment_type.usaepay.label;
      }
    }
    if (portalSettings?.fieldLabels) {
      this.fieldLabels = portalSettings.fieldLabels;
    }
    if (portalSettings?.arrangement && portalSettings.arrangement?.start_date_buffer) {
      this.startDateBuffer = portalSettings.arrangement.start_date_buffer ?  parseInt(portalSettings.arrangement.start_date_buffer):  0;
    }
    console.log(this.fieldLabels);
    this.initForm();
  }

  initForm() {
    const addresses = this.debtorDetails?.addresses.filter(
      (add) => add.address_main == 1
    ) || [{}];

    this.outstanding = this.caseDetails.d_outstanding.replace(
      this.caseDetails.currency_symbol,
      ''
    );

    this.recurringPaymentPlanForm = this.formBuilder.group({
      amount: [this.amount ? this.amount : this.outstanding, [Validators.required]],
      freq: ['4', [Validators.required]],
      start: [moment().format('YYYY-MM-DD'), [Validators.required]],
      ref_amount: [this.outstanding, [Validators.required]],

      debtor_name: [
        this.debtorDetails.debtor_name ? this.debtorDetails.debtor_name : '',
        [Validators.required],
      ],
      card_number: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{12,20}$')],
      ],
      cvv: ['', [Validators.required, Validators.pattern('^[0-9]{3,4}$')]],

      expiration: ['', [Validators.required]],
      address_ln1: [
        addresses[0]?.address_ln1 ? addresses[0]?.address_ln1 : '',
        [Validators.required],
      ],
      address_ln2: [
        addresses[0]?.address_ln2 ? addresses[0]?.address_ln2 : '',
        [],
      ],
      address_ln3: [
        addresses[0]?.address_ln3 ? addresses[0]?.address_ln3 : '',
        [],
      ],
      town: [
        addresses[0]?.address_town ? addresses[0]?.address_town : '',
        [Validators.required],
      ],
      address_postcode: [
        addresses[0]?.address_postcode ? addresses[0]?.address_postcode : '',
        [Validators.required],
      ],
    });
    if (this.customPlan) {
      this.recurringPaymentPlanForm.patchValue({
        amount: this.amount + '',
        freq: this.freq + '',
        start: moment().format('YYYY-MM-DD'),
      });
    }
  }

  async dismiss() {
    this.modalCtrl.dismiss({
      saved: false,
    });
  }

  cancel() {
    this.dismiss();
  }

  async openCalendar(input) {
    this.date = input;
    const options: CalendarModalOptions = {
      title: '',
      closeIcon: true,
      doneIcon: true,
    };
    let toDate = new Date();
    toDate.setDate(toDate.getDate() + this.startDateBuffer - 1);
    if(this.customPlan){
      options.from = new Date();
      options.to = toDate;
    }
    const myCalendar = await this.modalCtrl.create({
      component: CalendarModal,
      componentProps: {
        options: options,
      },
    });

    myCalendar.onDidDismiss().then((response) => {
      console.log(this.date);
      const date = this.date;
      console.log(response);
      if (response.role == 'done') {
        this.recurringPaymentPlanForm.controls[date].patchValue(
          moment(response.data.dateObj).format('YYYY-MM-DD'),
          { onlySelf: true }
        );
        this.recurringPaymentPlanForm.controls[date].updateValueAndValidity();
      }
    });
    await myCalendar.present();
  }

  save() {
    if (this.recurringPaymentPlanForm.valid) {
      this.saveBtnDisabled = true;
      this.commonService.showLoader();
      const submitObj = {
        ...this.recurringPaymentPlanForm.value,
        expiration: moment(
          this.recurringPaymentPlanForm.value.expiration
        ).format('MM/YY'),
        debtor_id: this.debtorDetails.debtor_id,
        mode: 'make',
        payment_method: 'usaepay',
      };
      console.log(submitObj);
      this.caseService
        .newRecurringCardPaymentPlan(this.caseId, submitObj)
        .subscribe((res: any) => {
          this.saveBtnDisabled = false;
          this.commonService.dismissLoader();
          if (res.status == 200) {
            this.commonService.showToast(
              'Action Performed Successfully!',
              'success'
            );
            this.modalCtrl.dismiss({
              saved: true,
            });
          } else {
            this.commonService.showToast(res['message'], 'danger');
            this.dismiss();
          }
        });
    }
  }
}
