import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { CommonService } from '../services/common.service';
import { ReinitService } from '../services/reinit.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  loginForm: FormGroup;
  dpa_check: string;
  dynamicLogin: number = 0;
  fieldList = {
    case_ref:{
      key: 'case_ref', label:'Case Ref'
    },
    surname:{
      key: 'surname', label:'Surname'
    }
  };
  fieldKeys;
  isMobile = false;
  title = 'Lateral Debtor Portal';
  logo_url = '';
  loginBtnDisabled = false;
  datepicker_date_format = 'DD/MM/YYYY';

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private platform: Platform,
    private reinitService: ReinitService
  ) { 
    this.dpa_check = '';
    this.fieldKeys = Object.keys(this.fieldList);
  }

  ngOnInit() {
    this.initForm();
  }

  ionViewWillEnter(){
    this.isMobile = this.platform.is('mobile');
    if(localStorage.getItem('dynamicLogin')){
      this.dynamicLogin = parseInt(localStorage.getItem('dynamicLogin'));
      // localStorage.removeItem('dynamicLogin')
      localStorage.removeItem('default_webform_configured');
    }
    this.route.queryParams.subscribe(params => {
      if (params && params.dynamic) {
        this.dynamicLogin = params.dynamic;
      }
    });
    if(this.router.url == '/viewmycase'){
      this.dynamicLogin = 1;
      localStorage.setItem('from_viewmycase',this.dynamicLogin.toString());
    } else {
      localStorage.removeItem('from_viewmycase');
    }
    this.fetchSettings();
  }

  initForm() {
    this.loginForm = this.formBuilder.group({
      dob: ['', Validators.pattern('((0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](?:19|20)[0-9]{2}$)|((0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](?:19|20)[0-9]{2}$)')],
      postcode: [''],
      surname: [''],
      case_id: [''],
      case_ref: [''],
      mobile_no: [''],
      ssn: ['',Validators.pattern('[0-9]{4}')],
    });
    if(localStorage.getItem('dpa_check')){
      this.dpa_check = localStorage.getItem('dpa_check');
    }
  }

  login(){
    if ((this.dpa_check == 'dob' && this.loginForm.value.dob != '') || (this.dpa_check == 'postcode' && this.loginForm.value.postcode != '')) {
      console.log('login');
      this.loginBtnDisabled = true;
      this.commonService.showLoader('Loading', 2000);
      let debtor_id = localStorage.getItem('debtor_id');
      let form_slug = localStorage.getItem('form_slug');
      let dpa_pass_key;
      switch (this.dpa_check) {
        case 'dob':
          dpa_pass_key = this.loginForm.value.dob.split("/").reverse().join("-");
          if(this.datepicker_date_format == "MM/DD/YYYY"){
            let dateSplit = this.loginForm.value.dob.split("/");
            dpa_pass_key = dateSplit[2] + '-' + dateSplit[0] + '-' + dateSplit[1];
          }
          break;
        case 'postcode':
          dpa_pass_key = this.loginForm.value.postcode;
          break;
      }
      this.authService.authenticate(debtor_id,form_slug,this.dpa_check,dpa_pass_key).subscribe((res)=>{
        this.commonService.dismissLoader();
        if(res['result']){
          localStorage.setItem('remote_token',res['data']);
          let navigationExtras: NavigationExtras = {
            queryParams: {
              fslug: localStorage.getItem('form_slug'),
              cid: localStorage.getItem('case_id'),
              did: localStorage.getItem('debtor_id'),
              dpa: localStorage.getItem('dpa_check')
            },
            replaceUrl: true
          };
          localStorage.setItem('dynamicLogin',this.dynamicLogin.toString());
          this.router.navigate(['/home'],navigationExtras);
        } else {
          this.commonService.showToast('Invalid Date of Birth','danger');
          this.commonService.showToast(res['message'],'danger');
          this.loginBtnDisabled = false;
        }
      })
    }
  }

  addSlash(e){
    if(e.which !== 8) { 
      let length = this.loginForm.value.dob.length;
      if(length === 2 || length === 5){
        let dob = this.loginForm.value.dob + '/';
        this.loginForm.controls['dob'].patchValue(dob);
      }
    }
  }

  login_direct(){
    let data = this.loginForm.value;
    let filtered_data = {};
    Object.keys(data).filter(value=> data[value] != '' ? (value == 'dob') ? filtered_data[value] = data[value].split("/").reverse().join("-") : filtered_data[value] = data[value] : '');
    if('dob' in filtered_data){
      let dateSplit = this.loginForm.value.dob.split("/");
      Object.assign(filtered_data,{dob: dateSplit[2] + '-' + dateSplit[0] + '-' + dateSplit[1]});
    }
    if(Object.keys(filtered_data).length >= 2){
      console.log('login_direct');
      this.loginBtnDisabled = true;
      this.commonService.showLoader('Loading', 2000);
      this.authService.authenticate_direct(filtered_data ).subscribe((res)=>{
        this.commonService.dismissLoader();
        if(res['result']){
          localStorage.setItem('remote_token',res['data']['remote_token']);
          localStorage.setItem('case_id',res['data']['case_id']);
          localStorage.setItem('debtor_id',res['data']['debtor_id']);
          localStorage.setItem('default_webform_configured',res['data']['default_webform_configured']);
          localStorage.setItem('dynamicLogin',this.dynamicLogin.toString());
          this.reinitService.triggerReinit();
          if(res['data']['default_webform_configured']){
            localStorage.setItem('form_slug',res['data']['form_slug']);
            localStorage.setItem('dpa_check',res['data']['dpa_check']);
            let navigationExtras: NavigationExtras = {
              queryParams: {
                fslug: res['data']['form_slug'],
                cid: res['data']['case_id'],
                did: res['data']['debtor_id'],
                dpa: res['data']['dpa_check']
              },
              replaceUrl: true
            };
            this.router.navigate(['/home'],navigationExtras);
          } else {
            this.router.navigate(['/main'],{
              replaceUrl: true // Add this option.
            });
          }
        } else {
          this.loginBtnDisabled = false;
          this.commonService.showToast(res['message'],'danger');
        }
      })
    }
  }

  fetchSettings(){
    this.authService.portalSettings().subscribe((res:any)=>{
      let portalSettings = res.data;
      localStorage.setItem('portal_settings',JSON.stringify(portalSettings));
      if(this.dynamicLogin){
      
        if(portalSettings.dynamic_field){
          if(Object.keys(portalSettings?.dynamic_field ? portalSettings?.dynamic_field : {}).length >= 2){
            this.fieldList = portalSettings.dynamic_field;
            this.fieldKeys = Object.keys(this.fieldList);
          }
        }
      }
      if(portalSettings?.portal_details?.title){
        this.title = portalSettings.portal_details.title;
      }
      if(portalSettings?.portal_details?.logo_url){
        this.logo_url = portalSettings.portal_details.logo_url;
      }
      if(portalSettings?.datepicker_date_format){
        this.datepicker_date_format = portalSettings.datepicker_date_format;
      }
    });
  }
}
