import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private http: HttpClient,
    ) { }

    authenticate(debtor_id ,form_slug , dpa_check, dpa_pass_key) {
        const apiURL = localStorage.getItem('server_url') + 'b/system/v1/session/debtor_login?dynamic=0';
        const data = {
            debtor_id: debtor_id,
            form_slug: form_slug,
            dpa_check: dpa_check,
            dpa_pass_key: dpa_pass_key,
        };
        return this.http.post(apiURL, data);
    }

    authenticate_direct(data) {
        const apiURL = localStorage.getItem('server_url') + 'b/system/v1/session/debtor_login?dynamic=1';
        return this.http.post(apiURL, data);
    }

    portalSettings(){
        const apiURL =  localStorage.getItem('server_url') + 'b/system/v1/session/debtor_login?fetchSettings=1';
        return this.http.post(apiURL,{});
    }

}
