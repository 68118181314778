import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CaseService {
  constructor(private http: HttpClient) {}

  getCaseDetails(debtor_id) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/get_case_details?debtor_id=${debtor_id}&source=debtor_API`;
    return this.http.get(apiURL);
  }

  getDebtorDetails(debtor_id) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/get_debtor_details?debtor_id=${debtor_id}&source=debtor_API`;
    return this.http.get(apiURL);
  }

  getCaseCustomVariables(case_id, regenerate = false) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/get_case_custom_variables?case_id=${case_id}&regenerate=${regenerate}&source=debtor_API`;
    return this.http.get(apiURL);
  }

  paymentPlan(data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/payment_plan?source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  contactForm(data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/contact_form?source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  getGovUKPayLink(data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/govuk_pay_link?source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeUSAePayment(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/usa_epay_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeUSAePaymentRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/usa_epay_payment_request?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makePayrocPaymentRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/payroc_payment_request?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makePayRocPayment(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/payroc_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  createArrangement(type, caseId, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/clickdebt_panel_layout/arrangements/case_actions_panels/${type}/${caseId}?source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  newRecurringCardPaymentPlan(caseId, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/clickdebt_panel_layout/payment/case_actions_panels/case_action_recurring_payment/${caseId}?source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeGocardlessPaymentRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/gocardless_payment_request?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeGoCardlessPayment(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/gocardless_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }
    
  makeAcquiredPaymentRequest(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/acquired_payment_request?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }

  makeAcquiredPayment(case_id, data) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/acquired_payment?case_id=${case_id}&source=debtor_API`;
    return this.http.post(apiURL, data);
  }
}
