import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-mobipaid',
  templateUrl: './mobipaid.page.html',
  styleUrls: ['./mobipaid.page.scss'],
})
export class MobipaidPage implements OnInit {

  @Input() caseId;
  @Input() debtorDetails;
  mobipaid_url;
  refreshBtnDisable = false;
  title = 'Lateral Payment Portal';

  constructor(
    private modalCtrl: ModalController,
    private caseService: CaseService,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if(portalSettings?.payment_type){
      let payment_type = portalSettings?.payment_type ? portalSettings?.payment_type : {};
      if(payment_type?.mobipaid && payment_type?.mobipaid?.label){
        this.title = payment_type.mobipaid.label;
      }
    }
    this.getmobipaidurl();
  }

  async getmobipaidurl(regenerate = false) {
    this.commonService.showLoader();
    this.refreshBtnDisable = true;
    this.caseService.getCaseCustomVariables(this.caseId,regenerate).subscribe((res: any) => {
      if (res.result) {
        this.mobipaid_url = res.data;
      } else {
        this.dismiss();
        this.commonService.showToast(res.message,'danger');
      }
      this.refreshBtnDisable = false;
      this.commonService.dismissLoader();
    });
  }

  async dismiss() {
    this.modalCtrl.dismiss({
      saved: false
    });
  }
  cancel() {
    this.dismiss();
  }

  async doRefresh() {
    this.getmobipaidurl(true);
  }
}
