import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-govukpay',
  templateUrl: './govukpay.page.html',
  styleUrls: ['./govukpay.page.scss'],
})
export class GovukpayPage implements OnInit {

  @Input() caseId;
  @Input() firstCaseDetails;
  @Input() totalOutstanding;
  @Input() debtorDetails;
  title = 'Lateral Payment Portal';
  govUKPayForm: FormGroup;
  saveBtnDisabled = false;
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private caseService: CaseService,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    this.initForm();
  }
  
  initForm() {
    console.log(window.location);
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if(portalSettings?.payment_type){
      let payment_type = portalSettings?.payment_type ? portalSettings?.payment_type : {};
      if(payment_type?.govukpay && payment_type?.govukpay?.label){
        this.title = payment_type.govukpay.label;
      }
    }
    this.govUKPayForm = this.formBuilder.group({
      amount: [this.totalOutstanding ? this.totalOutstanding : 0, [Validators.required]],
      reference: [this.firstCaseDetails?.ref ? this.firstCaseDetails?.ref : '', [Validators.required]],
      description: [this.firstCaseDetails?.offense ? this.firstCaseDetails?.offense : '', [Validators.required]],
      email: [this.debtorDetails?.debtor_email ? this.debtorDetails?.debtor_email : '', [Validators.required,Validators.email]],
      cardholder_name: [this.debtorDetails?.debtor_name ? this.debtorDetails?.debtor_name : '', [Validators.required]],
      line1: [this.debtorDetails?.addresses[0]?.address_ln1 ? this.debtorDetails?.addresses[0]?.address_ln1 : '', [Validators.required]],
      line2: [this.debtorDetails?.addresses[0]?.address_ln2 ? this.debtorDetails?.addresses[0]?.address_ln2 : '', []],
      city: [this.debtorDetails?.addresses[0]?.address_town ? this.debtorDetails?.addresses[0]?.address_town : '', [Validators.required]],
      postcode: [this.debtorDetails?.addresses[0]?.address_postcode ? this.debtorDetails?.addresses[0]?.address_postcode : '', [Validators.required]],
    });
  }

  async save() {
    if (this.govUKPayForm.valid) {
      this.saveBtnDisabled = true;
      let obj = this.govUKPayForm.value;
      obj.amount = this.govUKPayForm.value.amount * 100;
      obj.CaseID = this.caseId;
      obj.SummonsNo = this.firstCaseDetails?.ref ? this.firstCaseDetails?.ref : this.caseId;
      obj.AccountNo = this.firstCaseDetails?.cl_ref ? this.firstCaseDetails?.cl_ref : this.caseId;
      obj.return_url = window.location.href;
      this.caseService.getGovUKPayLink(obj).subscribe((res: any) => {
        this.saveBtnDisabled = false
        console.log(res);
        if(res.result){
          if(res.data?.payment_url){
            var win = window.open(res.data.payment_url,'_self');
            if (win) {
              win.focus();
            }
          }
        } else {
          this.commonService.showToast(res['message'],'danger');
          this.dismiss();
        }        
      });
    }
  }

  async dismiss() {
    this.modalCtrl.dismiss({
      saved: false
    });
  }
  cancel() {
    this.dismiss();
  }
}
