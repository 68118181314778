import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { USAepayPage } from './usaepay.page';

const routes: Routes = [
  {
    path: '',
    component: USAepayPage,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class USAepayPageRoutingModule {}
