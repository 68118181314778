import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MobipaidPageRoutingModule } from './mobipaid-routing.module';

import { MobipaidPage } from './mobipaid.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MobipaidPageRoutingModule,
    ReactiveFormsModule,

  ],
  declarations: [MobipaidPage]
})
export class MobipaidPageModule {}
