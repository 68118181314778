import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { CardService } from 'src/app/services/card.service';
@Component({
  selector: 'app-authorize-card',
  templateUrl: './authorize-card.page.html',
  styleUrls: ['./authorize-card.page.scss'],
})
export class AuthorizeCardPage implements OnInit {
  @Input() caseId;
  @Input() debtorId;
  @Input() selectedCases;
  @Input() debtorDetails;
  @Input() paymentMethod;
  addCardForm: FormGroup;
  datemin = moment().format('YYYY-MM-DD');
  datemax = moment().add('100', 'years').format('YYYY-MM-DD');
  savedCardList = [];
  showCards: any = {
    addCard: {
      show: true,
    },
    cardList: {
      show: true,
    },
  };
  fieldLabels = {};
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private alertCtrl: AlertController,
    private cardService: CardService
  ) {}

  ngOnInit() {
    this.initForm();
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.fieldLabels) {
      this.fieldLabels = portalSettings.fieldLabels;
    }
  }
  async ionViewWillEnter() {
    this.getCardDetails();
  }
  getCardDetails() {
    if (this.paymentMethod) {
      let method = '';
      let id = this.debtorId;
      const linkedCases = this.selectedCases;
      let grpSelCases = '';
      if (linkedCases.length) {
        method = 'group';
        linkedCases.push(this.caseId);
        linkedCases.forEach((element) => {
          grpSelCases += element + '@@';
        });
        id = grpSelCases;
      }
      this.cardService
        .getSavedCards(id, this.paymentMethod, method)
        .subscribe((res) => {
          if (res) {
            const cards = [];
            Object.keys(res).forEach((element) => {
              cards.push({ key: element, value: res[element] });
            });
            this.savedCardList = cards;
          }
        });
    }
  }
  initForm() {
    this.addCardForm = this.formBuilder.group({
      card_number: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{12,20}$')],
      ],
      card_expiry: ['', [Validators.required]],
      card_cvc: ['', [Validators.required, Validators.pattern('^[0-9]{3,4}$')]],
      debtor_name: [
        this.debtorDetails.debtor_name ? this.debtorDetails.debtor_name : '',
        [Validators.required],
      ],
      description: [''],
      address_ln1: [
        this.debtorDetails.addresses[0]?.address_ln1
          ? this.debtorDetails.addresses[0]?.address_ln1
          : '',
        [Validators.required],
      ],
      address_ln2: [
        this.debtorDetails.addresses[0]?.address_ln2
          ? this.debtorDetails.addresses[0]?.address_ln2
          : '',
        [],
      ],
      address_ln3: [
        this.debtorDetails.addresses[0]?.address_ln3
          ? this.debtorDetails.addresses[0]?.address_ln3
          : '',
        [],
      ],
      town: [
        this.debtorDetails.addresses[0]?.address_town
          ? this.debtorDetails.addresses[0]?.address_town
          : '',
        [Validators.required],
      ],
      address_postcode: [
        this.debtorDetails.addresses[0]?.address_postcode
          ? this.debtorDetails.addresses[0]?.address_postcode
          : '',
        [Validators.required],
      ],
    });
  }
  save() {
    if (this.addCardForm.valid) {
      this.commonService.showLoader();
      const usaepayObj = {
        address_ln1: this.addCardForm.value.address_ln1,
        address_ln2: this.addCardForm.value.address_ln2,
        address_ln3: this.addCardForm.value.address_ln3,
        town: this.addCardForm.value.town,
        address_postcode: this.addCardForm.value.address_postcode,
        description: this.addCardForm.value.description,
        debtor_name: this.addCardForm.value.debtor_name,
        card_number: this.addCardForm.value.card_number,
        expiration: moment(this.addCardForm.value.card_expiry).format('MMYY'),
      };
      this.cardService
        .addCard(this.paymentMethod, this.debtorId, usaepayObj)
        .subscribe((res: any) => {
          this.commonService.dismissLoader();
          console.log(res);
          let resData = res.data;
          if (resData.result) {
            this.commonService.showToast(
              resData.data.message ? resData.data.message : resData.message
            );
            this.getCardDetails();
          }
        });
    }
  }

  dismiss() {
    this.modalCtrl.dismiss({
      saved: false,
    });
  }
  toggleShow(object) {
    object.show = !object.show;
  }
  isShown(object) {
    return object.show;
  }
  async delete(id) {
    const alert = await this.alertCtrl.create({
      header: 'Delete Card',
      message: 'Are you sure you want to Delete Card?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Delete',
          handler: async () => {
            this.cardService.deleteCard(id,this.debtorId).subscribe((response: any) => {
              console.log(response);
              if (response.status) {
                this.commonService.showToast(response.message);
                this.getCardDetails();
              } else {
                this.commonService.showToast(response.message);
              }
            });
          },
        },
      ],
    });
    await alert.present();
  }
}
