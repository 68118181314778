import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { GoCardlessPageRoutingModule } from './gocardless-routing.module';

import { GoCardlessPage } from './gocardless.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    GoCardlessPageRoutingModule,
  ],
  declarations: [GoCardlessPage],
})
export class GoCardlessPageModule {}
