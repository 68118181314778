import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
declare var acquired: any;

@Component({
  selector: 'app-acquired',
  templateUrl: './acquired.page.html',
  styleUrls: ['./acquired.page.scss'],
})
export class AcquiredPage implements OnInit {
  @Input() caseId;
  @Input() responseData;
  title = 'Lateral Payment Portal';
  acquired: any;
  AcquiredForm: FormGroup;
  saveBtnDisabled = false;
  isConfigured = false;
  amount = 0;
  link_id = '';
  payment_link = '';
  fieldLabels = {};
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private caseService: CaseService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.saveBtnDisabled = false;
    this.amount = this.responseData?.net_amount;
    this.link_id = this.responseData?.link_id;
    this.payment_link = this.responseData?.link_id ? 'https://test-pay.acquired.com/v1/'+this.link_id : '';
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.payment_type) {
      let payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
      if (payment_type?.acquired && payment_type?.acquired?.label) {
        this.title = payment_type.acquired.label;
      }
    }
    if (portalSettings?.fieldLabels) {
      this.fieldLabels = portalSettings.fieldLabels;
    }
    this.isConfigured = true;
  }


  proceedPayment() {
    this.saveBtnDisabled = true;
    let obj = this.AcquiredForm.value;
    obj['gateway_id'] = this.responseData.id;
    this.caseService
      .makeAcquiredPayment(this.caseId, obj)
      .subscribe((res: any) => {
        this.saveBtnDisabled = false;
        this.commonService.dismissLoader();
        if (res.result) {
          this.commonService.showToast(res['message'], 'success');
          this.modalCtrl.dismiss({
            saved: true,
          });
        } else {
          this.commonService.showToast(res['message'], 'danger');
        }
      });
  }

  async dismiss() {
    this.modalCtrl.dismiss({
      saved: false,
    });
  }
}
